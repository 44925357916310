import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Period } from "src/app/interfaces/periods.interface";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { WalletService } from "src/app/services/wallet.service";
import { Chain } from "../../interfaces/contract.interface";
import BN from "bn.js";

@Component({
  selector: "app-staking-dialog",
  templateUrl: "./staking-dialog.component.html",
  styleUrls: ["./staking-dialog.component.scss"],
})
export class StakingDialogComponent implements OnInit {
  periods: Period[];
  allowance: BN = new BN("0");
  allowanceIsZero: boolean = true;
  balance: any;
  mintRequired: boolean = false;

  stakeForm: FormGroup = this.formBuilder.group({
    amount: [
      {
        value: "",
        disabled: true,
      },
      [Validators.required, Validators.pattern("^[0-9]*.?[0-9]*$")],
    ],
    lockingPeriod: [
      {
        value: null,
        disabled: true,
      },
      [Validators.required],
    ],
  });

  constructor(
    public dialogRef: MatDialogRef<StakingDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      chain: Chain;
      account: string;
    },
    private formBuilder: FormBuilder,
    private _wallet: WalletService,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    await this.load();
  }

  async load(): Promise<void> {
    this.mintRequired = await this._wallet.mintRequired(this.data.chain.id, this.data.account);
    this.balance = await this._wallet.getUserBalance(this.data.chain.id, this.data.account);
    this.periods = [];
    const periods = await this._wallet.getPeriods(this.data.chain.id);

    periods.forEach((period) => {
      if (period.enabled) {
        this.periods.push(period);
      }
    });

    this.allowance = await this._wallet.allowance(this.data.chain.id);
    this.allowanceIsZero = this.allowance.eq(new BN("0"));
    if (!this.allowanceIsZero) {
      this.stakeForm.controls["amount"].enable();
      this.stakeForm.controls["lockingPeriod"].enable();
    }
  }

  close() {
    this.dialogRef.close();
  }

  async stake() {
    if (!this.allowanceIsZero) {
      this.dialogRef.close({
        action: "stake",
        chain: this.data.chain,
        amount: this.stakeForm.value.amount,
        period: this.stakeForm.value.lockingPeriod,
        account: this.data.account,
      });
    } else {
      this.dialogRef.close({
        action: "approve",
        chain: this.data.chain,
      });
    }
  }

  async mint() {
    this.dialogRef.close({
      action: "mint",
      chain: this.data.chain,
      account: this.data.account,
    });
  }
}
