<div class="general-container">
  <div class="container-header">
    <h2 mat-dialog-title>
      {{"swap-dialog.title" | translate}}
    </h2>
    <mat-icon aria-hidden="false" aria-label="Close Icon" class="close_icon" (click)="close()">highlight_off</mat-icon>
  </div>
  <mat-dialog-content>
    <div class="swap-items-container">
      <a class="swap-item" href="https://pancakeswap.finance/swap?chain=bsc&inputCurrency=BNB&outputCurrency=0x7269d98Af4aA705e0B1A5D8512FadB4d45817d5a" target="_blank" >
        <img src="./../../../assets/images/ic_pancakeswap.svg" alt="" />
        <h3>Pancakeswap</h3>
      </a>
    <a class="swap-item" href="https://app.uniswap.org/#/swap" target="_blank">
      <img src="./../../../assets/images/ic_uniswap.svg" alt="" />
      <h3>Uniswap</h3>
    </a>
    </div>
  </mat-dialog-content>
</div>
