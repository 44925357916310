<div mat-dialog-title class="title">
  <div class="title-text">
    <h2>{{ title }}</h2>
    <h3 *ngIf="isMetamaskInstalled">{{"wallets-dialog.h3" | translate}}</h3>
  </div>
</div>
<mat-dialog-content>
  <div *ngIf="isMetamaskInstalled" class="button ripple" (click)="connect('metamask')">
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#212121" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9399 33.3453L21.3471 34.2493V33.0672L21.6253 32.7891H23.5722V34.1797V35.1532H21.4862L18.9134 34.0407L17.9399 33.3453Z"
        fill="#CDBDB2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.0652 33.3453L25.7276 34.2493V33.0672L25.4495 32.7891H23.5026V34.1797V35.1532H25.5886L28.1613 34.0407L29.0652 33.3453Z"
        fill="#CDBDB2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.6253 30.7763L21.3472 33.0709L21.6948 32.7927H25.3105L25.7277 33.0709L25.4496 30.7763L24.8933 30.4286L22.112 30.4982L21.6253 30.7763Z"
        fill="#393939"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.748 15.2645L21.4168 19.1584L22.1817 30.4924H24.8935L25.7279 19.1584L27.2576 15.2645H19.748Z"
        fill="#F89C35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4469 23.8193L10.5 29.4514L15.3673 29.1733H18.4963V26.7397L18.3572 21.7333L17.6619 22.2895L12.4469 23.8193Z"
        fill="#F89D35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1321 24.4453L21.8339 24.5844L21.2081 27.5048L18.4963 26.8094L16.1321 24.4453Z"
        fill="#D87C30"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1321 24.5156L18.4963 26.7407V28.9658L16.1321 24.5156Z"
        fill="#EA8D3A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4962 26.8125L21.2775 27.5078L22.1815 30.4978L21.5556 30.8454L18.4962 29.0376V26.8125Z"
        fill="#F89D35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4962 29.0323L17.9399 33.3434L21.6253 30.7707L18.4962 29.0323Z"
        fill="#EB8F35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.8341 24.5859L22.1818 30.4963L21.1387 27.4716L21.8341 24.5859Z"
        fill="#EA8E3A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2978 29.1019L18.4963 29.0323L17.9401 33.3434L15.2978 29.1019Z"
        fill="#D87C30"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0297 35.2216L17.94 33.3441L15.2977 29.1027L10.5 29.4503L12.0297 35.2216Z"
        fill="#EB8F35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.4167 19.1584L18.4268 21.6616L16.1321 24.443L21.8339 24.6516L21.4167 19.1584Z"
        fill="#E8821E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9399 33.3461L21.6253 30.7734L21.3471 32.9985V34.2501L18.8439 33.7633L17.9399 33.3461Z"
        fill="#DFCEC3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.0652 33.3461L25.4495 30.7734L25.7276 32.9985V34.2501L28.2308 33.7633L29.0652 33.3461Z"
        fill="#DFCEC3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.3041 25.8416L21.069 27.4409L18.3572 26.7455L20.3041 25.8416Z"
        fill="#393939"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.96 12L21.4166 19.1619L19.8174 15.268L11.96 12Z"
        fill="#E88F35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.96 12L10.7084 15.8243L11.4038 19.9963L10.917 20.2745L11.6124 20.9003L11.0561 21.387L11.821 22.0823L11.3342 22.4995L12.4468 23.8902L17.6618 22.2909C20.2113 20.2513 21.4629 19.2083 21.4166 19.1619C21.3702 19.1156 18.218 16.7283 11.96 12Z"
        fill="#8E5A30"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.5584 23.8193L36.5054 29.4514L31.638 29.1733H28.5091V26.7397L28.6482 21.7333L29.3435 22.2895L34.5584 23.8193Z"
        fill="#F89D35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.8732 24.4453L25.1715 24.5844L25.7972 27.5048L28.5091 26.8094L30.8732 24.4453Z"
        fill="#D87C30"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.8732 24.5156L28.5091 26.7407V28.9658L30.8732 24.5156Z"
        fill="#EA8D3A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.5092 26.8125L25.7279 27.5078L24.8239 30.4978L25.4497 30.8454L28.5092 29.0376V26.8125Z"
        fill="#F89D35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.5092 29.0323L29.0654 33.3434L25.4497 30.8402L28.5092 29.0323Z"
        fill="#EB8F35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.1713 24.5859L24.8236 30.4963L25.8666 27.4716L25.1713 24.5859Z"
        fill="#EA8E3A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.7076 29.1019L28.509 29.0323L29.0653 33.3434L31.7076 29.1019Z"
        fill="#D87C30"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.9756 35.2216L29.0654 33.3441L31.7076 29.1027L36.5054 29.4503L34.9756 35.2216Z"
        fill="#EB8F35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.5887 19.1584L28.5786 21.6616L30.8732 24.443L25.1715 24.6516L25.5887 19.1584Z"
        fill="#E8821E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.7013 25.8416L25.9363 27.4409L28.6482 26.7455L26.7013 25.8416Z"
        fill="#393939"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.0453 12L25.5888 19.1619L27.188 15.268L35.0453 12Z"
        fill="#E88F35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.0453 12L36.2969 15.8243L35.6016 19.9963L36.0883 20.2745L35.393 20.9003L35.9493 21.387L35.1844 22.0823L35.6711 22.4995L34.5586 23.8902L29.3436 22.2909C26.794 20.2513 25.5424 19.2083 25.5888 19.1619C25.6352 19.1156 28.7873 16.7283 35.0453 12Z"
        fill="#8E5A30"
      />
    </svg>

    <div class="text-content text-metamask">
      <span>Metamask</span>
    </div>
  </div>

  <div class="button ripple" (click)="connect('walletConnect')">
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#212121" />
      <g clip-path="url(#clip0_65_1568)">
        <path
          d="M15.9028 18.7346C20.3747 14.3562 27.6251 14.3562 32.097 18.7346L32.6352 19.2615C32.8588 19.4804 32.8588 19.8354 32.6352 20.0543L30.7941 21.8568C30.6823 21.9663 30.501 21.9663 30.3892 21.8568L29.6486 21.1317C26.5289 18.0773 21.4709 18.0773 18.3512 21.1317L17.558 21.9083C17.4462 22.0177 17.265 22.0177 17.1532 21.9083L15.3121 20.1057C15.0885 19.8868 15.0885 19.5318 15.3121 19.3129L15.9028 18.7346ZM35.9045 22.4625L37.543 24.0667C37.7666 24.2857 37.7666 24.6406 37.543 24.8595L30.1547 32.0934C29.9311 32.3124 29.5686 32.3124 29.345 32.0934C29.345 32.0934 29.345 32.0934 29.345 32.0934L24.1012 26.9593C24.0453 26.9046 23.9546 26.9046 23.8987 26.9593C23.8987 26.9593 23.8987 26.9593 23.8987 26.9593L18.655 32.0934C18.4314 32.3124 18.0689 32.3124 17.8453 32.0934C17.8453 32.0934 17.8453 32.0934 17.8453 32.0934L10.4568 24.8594C10.2332 24.6405 10.2332 24.2856 10.4568 24.0666L12.0953 22.4624C12.3189 22.2434 12.6814 22.2434 12.905 22.4624L18.1489 27.5966C18.2048 27.6513 18.2954 27.6513 18.3513 27.5966C18.3513 27.5966 18.3513 27.5966 18.3513 27.5966L23.595 22.4624C23.8185 22.2434 24.1811 22.2434 24.4047 22.4623C24.4047 22.4623 24.4047 22.4623 24.4047 22.4624L29.6485 27.5966C29.7044 27.6513 29.7951 27.6513 29.851 27.5966L35.0948 22.4625C35.3184 22.2435 35.6809 22.2435 35.9045 22.4625Z"
          fill="#3B99FC"
        />
      </g>
      <defs>
        <clipPath id="clip0_65_1568">
          <rect width="27.4286" height="16.9143" fill="white" transform="translate(10.2857 15.4286)" />
        </clipPath>
      </defs>
    </svg>

    <div class="text-content">
      <span>Wallet Connect</span>
    </div>
  </div>
  <div *ngIf="!isMetamaskInstalled" class="button ripple" (click)="goToMetamask()">
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#212121" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9399 33.3453L21.3471 34.2493V33.0672L21.6253 32.7891H23.5722V34.1797V35.1532H21.4862L18.9134 34.0407L17.9399 33.3453Z"
        fill="#CDBDB2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.0652 33.3453L25.7276 34.2493V33.0672L25.4495 32.7891H23.5026V34.1797V35.1532H25.5886L28.1613 34.0407L29.0652 33.3453Z"
        fill="#CDBDB2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.6253 30.7763L21.3472 33.0709L21.6948 32.7927H25.3105L25.7277 33.0709L25.4496 30.7763L24.8933 30.4286L22.112 30.4982L21.6253 30.7763Z"
        fill="#393939"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.748 15.2645L21.4168 19.1584L22.1817 30.4924H24.8935L25.7279 19.1584L27.2576 15.2645H19.748Z"
        fill="#F89C35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4469 23.8193L10.5 29.4514L15.3673 29.1733H18.4963V26.7397L18.3572 21.7333L17.6619 22.2895L12.4469 23.8193Z"
        fill="#F89D35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1321 24.4453L21.8339 24.5844L21.2081 27.5048L18.4963 26.8094L16.1321 24.4453Z"
        fill="#D87C30"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1321 24.5156L18.4963 26.7407V28.9658L16.1321 24.5156Z"
        fill="#EA8D3A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4962 26.8125L21.2775 27.5078L22.1815 30.4978L21.5556 30.8454L18.4962 29.0376V26.8125Z"
        fill="#F89D35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4962 29.0323L17.9399 33.3434L21.6253 30.7707L18.4962 29.0323Z"
        fill="#EB8F35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.8341 24.5859L22.1818 30.4963L21.1387 27.4716L21.8341 24.5859Z"
        fill="#EA8E3A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2978 29.1019L18.4963 29.0323L17.9401 33.3434L15.2978 29.1019Z"
        fill="#D87C30"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0297 35.2216L17.94 33.3441L15.2977 29.1027L10.5 29.4503L12.0297 35.2216Z"
        fill="#EB8F35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.4167 19.1584L18.4268 21.6616L16.1321 24.443L21.8339 24.6516L21.4167 19.1584Z"
        fill="#E8821E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9399 33.3461L21.6253 30.7734L21.3471 32.9985V34.2501L18.8439 33.7633L17.9399 33.3461Z"
        fill="#DFCEC3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.0652 33.3461L25.4495 30.7734L25.7276 32.9985V34.2501L28.2308 33.7633L29.0652 33.3461Z"
        fill="#DFCEC3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.3041 25.8416L21.069 27.4409L18.3572 26.7455L20.3041 25.8416Z"
        fill="#393939"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.96 12L21.4166 19.1619L19.8174 15.268L11.96 12Z"
        fill="#E88F35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.96 12L10.7084 15.8243L11.4038 19.9963L10.917 20.2745L11.6124 20.9003L11.0561 21.387L11.821 22.0823L11.3342 22.4995L12.4468 23.8902L17.6618 22.2909C20.2113 20.2513 21.4629 19.2083 21.4166 19.1619C21.3702 19.1156 18.218 16.7283 11.96 12Z"
        fill="#8E5A30"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.5584 23.8193L36.5054 29.4514L31.638 29.1733H28.5091V26.7397L28.6482 21.7333L29.3435 22.2895L34.5584 23.8193Z"
        fill="#F89D35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.8732 24.4453L25.1715 24.5844L25.7972 27.5048L28.5091 26.8094L30.8732 24.4453Z"
        fill="#D87C30"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.8732 24.5156L28.5091 26.7407V28.9658L30.8732 24.5156Z"
        fill="#EA8D3A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.5092 26.8125L25.7279 27.5078L24.8239 30.4978L25.4497 30.8454L28.5092 29.0376V26.8125Z"
        fill="#F89D35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.5092 29.0323L29.0654 33.3434L25.4497 30.8402L28.5092 29.0323Z"
        fill="#EB8F35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.1713 24.5859L24.8236 30.4963L25.8666 27.4716L25.1713 24.5859Z"
        fill="#EA8E3A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.7076 29.1019L28.509 29.0323L29.0653 33.3434L31.7076 29.1019Z"
        fill="#D87C30"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.9756 35.2216L29.0654 33.3441L31.7076 29.1027L36.5054 29.4503L34.9756 35.2216Z"
        fill="#EB8F35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.5887 19.1584L28.5786 21.6616L30.8732 24.443L25.1715 24.6516L25.5887 19.1584Z"
        fill="#E8821E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.7013 25.8416L25.9363 27.4409L28.6482 26.7455L26.7013 25.8416Z"
        fill="#393939"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.0453 12L25.5888 19.1619L27.188 15.268L35.0453 12Z"
        fill="#E88F35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.0453 12L36.2969 15.8243L35.6016 19.9963L36.0883 20.2745L35.393 20.9003L35.9493 21.387L35.1844 22.0823L35.6711 22.4995L34.5586 23.8902L29.3436 22.2909C26.794 20.2513 25.5424 19.2083 25.5888 19.1619C25.6352 19.1156 28.7873 16.7283 35.0453 12Z"
        fill="#8E5A30"
      />
    </svg>
    <div class="text-content metamask">
      <span>Go to Metamask</span>
    </div>
  </div>
</mat-dialog-content>
<!-- <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->
