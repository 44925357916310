<div class="general-container">
  <div class="container-header">
    <div>{{"staking-dialog.title" | translate }}</div>
  </div>
  <div *ngIf="mintRequired" class="mint-required">{{"staking-dialog.mint-required" | translate }}</div>

  <mat-dialog-content>
    <form class="createStake" [formGroup]="stakeForm">
      <div class="amount" *ngIf="!mintRequired">
        <div class="textWrapper">
          <p>{{"staking-dialog.content.amount" | translate}}</p>
          <p class="available">{{"staking-dialog.content.balance" | translate}}<span>${{balance  | number: "1.0-2" }}</span></p>
        </div>
        <input formControlName="amount" placeholder="Ex. 10.000 $SHI" type="number">
      </div>

      <div class="until" *ngIf="!mintRequired">
        <div class="textWrapper">{{"staking-dialog.content.locking-periods"  | translate }}</div>
        <div class="input">
          <select formControlName="lockingPeriod">
            <option *ngFor="let period of periods"
                    [value]="period.periodId">{{ period.lockingTime + ("staking-dialog.content.days" | translate) }}
              <span style="color: #a3a3a3">
              {{ period.taxBenefit + ("staking-dialog.content.apr" | translate) }}
            </span>
            </option>
          </select>
        </div>
      </div>

      <div class="actions">
        <button
          *ngIf="!mintRequired"
          [disabled]="(!allowanceIsZero && !stakeForm.valid)"
          (click)="stake()"
        >
          {{ !allowanceIsZero ? ("staking-dialog.content.stake" | translate) : ("staking-dialog.content.approve" | translate) }}
        </button>
        <button
          *ngIf="mintRequired"
          (click)="mint()"
        >
          MINT
        </button>
      </div>
    </form>
  </mat-dialog-content>
</div>
