import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { TranslationService } from "src/app/services/translate.service";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();
  language: number;

  constructor(private _translate: TranslationService, private _bottomSheetRef: MatBottomSheetRef<SidenavComponent>) {}

  ngOnInit(): void {
    this.language = this._translate.languages.findIndex((element) => element == this._translate.language);
  }

  onCloseSheet() {
    this._bottomSheetRef.dismiss();
  }

  languageChange(event: number) {
    this.language = event;
    this._translate.language = this._translate.languages[event];
  }
}
