import { Component, OnInit } from "@angular/core";
import { DataService } from "../../services/data.service";
import { ApiService } from "../../services/api.service";
import { RankingAndTiers } from "../../interfaces/responses.interface";
import { WalletService } from "../../services/wallet.service";

@Component({
  selector: "app-ranking-widget",
  templateUrl: "./ranking-widget.component.html",
  styleUrls: ["./ranking-widget.component.scss"],
})
export class RankingWidgetComponent implements OnInit {
  account?: string;
  ranking?: RankingAndTiers;

  constructor(private _data: DataService, private _api: ApiService) {}

  async ngOnInit(): Promise<void> {
    this._data.account.subscribe({
      next: async (res) => {
        this.account = res;
        this.updateRanking();
      },
    });
    this._data.checkTransactions.subscribe((res) => {
      if (res) {
        setTimeout(() => this.updateRanking(), 1000);
      }
    });
  }
  updateRanking() {
    if (this.account) {
      //"0xA5e94a4DcA699d2386719280D2f7c0821f1423b6"
      this._api.getRanking(this.account).subscribe({
        next: (res) => {
          this.ranking = res;
        },
        error: (err) => {},
        complete: () => {},
      });
    } else {
      this.ranking = undefined;
    }
  }
}
