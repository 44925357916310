<div class="funds">
  <div class="shiHandler">
    <div class="shiWrapper">
      <app-swap-button></app-swap-button>
      <p class="shi">$shi</p>
    </div>
    <p class="amount">${{ userBalance | number: "1.0-2" }}</p>
  </div>
  <div class="stackHandler">
    <p class="shi">mystake</p>
    <p class="amount">${{ balance | number: "1.0-2" }}</p>
  </div>
</div>
