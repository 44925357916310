<div class="general-container">
  <div class="container-header">
    <h2 mat-dialog-title>{{"loader-dialog.title" | translate}}</h2>
  </div>

  <mat-dialog-content>
    <span class="text"
      >{{"loader-dialog.content.text" | translate}}</span
    >
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </mat-dialog-content>
</div>
