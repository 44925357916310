import { Component, OnInit } from "@angular/core";
import { WalletService } from "../../services/wallet.service";
import { DataService } from "../../services/data.service";
import { ApiService } from "../../services/api.service";

@Component({
  selector: "app-account-balance",
  templateUrl: "./account-balance.component.html",
  styleUrls: ["./account-balance.component.scss"],
})
export class AccountBalanceComponent implements OnInit {
  account: string;
  userBalance: string = "0";
  balance: string = "0";
  loadedContract: boolean = false;
  constructor(private _wallet: WalletService, private _data: DataService, private _api: ApiService) {}

  ngOnInit(): void {
    this._data.account.subscribe({
      next: async (res) => {
        if (res) {
          this.account = res;
          this._data.loadedContract.subscribe({
            next: async (res) => {
              this.loadedContract = res;
              await this.loadData();
            },
          });
        }
      },
    });
    this._data.checkTransactions.subscribe((res) => {
      if (res) {
        setTimeout(() => this.loadData(), 1000);
      }
    });
  }

  async loadData() {
    if (this.loadedContract) {
      const chainsWithActiveContracts = await this._api.getChainWithActiveContracts();
      await this.loadBalance(chainsWithActiveContracts.map((chain) => chain.id));
    }
  }

  async loadBalance(chainIds: number[]) {
    //"0xA5e94a4DcA699d2386719280D2f7c0821f1423b6"
    this.balance = await this._wallet.getTotalBalance(chainIds, this.account);
    this.userBalance = await this._wallet.getTotalUserBalance(chainIds, this.account);
  }
}
