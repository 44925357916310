import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslatePipe } from "src/app/pipes/translate.pipe";
import { WalletService } from "src/app/services/wallet.service";

@Component({
  selector: "wallets-dialog",
  templateUrl: "wallets_dialog.component.html",
  styleUrls: ["wallets_dialog.component.scss"],
})
export class WalletsDialogComponent implements OnInit {
  title: string;
  isMetamaskInstalled: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<WalletsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _wallet: WalletService,
    private _translate: TranslatePipe
  ) {
    this.title = data ? data.title : this._translate.transform("wallets-dialog.h2.choose");
    this.isMetamaskInstalled = this._wallet.isMetamaskInstalled();
    this.title = this.isMetamaskInstalled ? this.title : this._translate.transform("wallets-dialog.h2.install");
  }

  ngOnInit() {}

  connect(wallet: string) {
    this.dialogRef.close(wallet);
  }

  goToMetamask() {
    this.dialogRef.close("install-metamask");
  }
}
