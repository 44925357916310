<div class="connect-wallet-component">
  <mat-label [matMenuTriggerFor]="menu" class="hidden flag">
    <img *ngIf="language == undefined" src="./../../../assets/images/languages.png" alt="select language" />
    <img *ngIf="language == 0" src="./../../../assets/images/english.svg" alt="" />
    <img *ngIf="language == 1" src="./../../../assets/images/spanish.svg" alt="" />
  </mat-label>
  <mat-menu class="language-menu" #menu="matMenu">
    <button mat-menu-item (click)="languageChange(0)">
      <img src="./../../../assets/images/english.svg" alt="" /> EN
    </button>
    <button mat-menu-item (click)="languageChange(1)">
      <img src="./../../../assets/images/spanish.svg" alt="" /> ES
    </button>
  </mat-menu>
  <button *ngIf="!account" (click)="connectWallet()" class="connect-button">
    {{ "header.connect-wallet" | translate }}
  </button>
  <div class="wallet" *ngIf="account" (click)="copyToClipboard()"
  >{{ account.substr(0, 6) }}...{{ account.substr(account.length - 4, account.length) }}</div>
</div>
