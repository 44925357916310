import { SurveyResponse } from "../interfaces/survey-response.interface";
import { ShippingAddress } from "../interfaces/shipping-address.interface";
import { WonResponse, RafflesResponse, RankingAndTiers } from "../interfaces/responses.interface";
import { WalletService } from "src/app/services/wallet.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { Chain, Contract } from "../interfaces/contract.interface";
import {
  CollectibleItemType,
  CollectibleNft,
  CollectibleNftResponse,
  CupsSubmitStatus,
} from "../interfaces/collectible-nft.interface";

@Injectable({ providedIn: "root" })
export class ApiService {
  base_url = environment.base_url;

  constructor(private http: HttpClient) {}

  getChainWithActiveContracts(): Promise<Chain[]> {
    return new Promise<Chain[]>((resolve, reject) => {
      this.http.get<Chain[]>(`${this.base_url}/contracts/grouped-by-chain`).subscribe({
        next: (response) => resolve(response),
        error: (err) => reject(err),
      });
    });
  }

  getStakingContracts(): Promise<Contract[]> {
    return new Promise<Contract[]>((resolve, reject) => {
      this.http.get<Contract[]>(`${this.base_url}/contracts/STAKING`).subscribe({
        next: (response) => resolve(response),
        error: (err) => reject(err),
      });
    });
  }

  getNftStakingContracts(): Promise<Contract[]> {
    return new Promise<Contract[]>((resolve, reject) => {
      this.http.get<Contract[]>(`${this.base_url}/contracts/NFTSTAKING`).subscribe({
        next: (response) => resolve(response),
        error: (err) => reject(err),
      });
    });
  }

  getNftCollectiblesContracts(): Promise<Contract[]> {
    return new Promise<Contract[]>((resolve, reject) => {
      this.http.get<Contract[]>(`${this.base_url}/contracts/NFT_COLLECTIBLE`).subscribe({
        next: (response) => resolve(response),
        error: (err) => reject(err),
      });
    });
  }

  getTokenIdsByContractAndOwner(contract: Contract, address: string): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      this.http
        .get<{ tokenIds: string[] }>(`${this.base_url}/nft-contracts/tokens-id-by-owner/${contract.id}/${address}`)
        .subscribe({
          next: (response) => resolve(response.tokenIds),
          error: (err) => reject(err),
        });
    });
  }

  getTransactions(address: string): Observable<any> {
    return this.http.get(`${this.base_url}/transactions/list/${address}`);
  }

  getRanking(address: string): Observable<RankingAndTiers> {
    return this.http.get<RankingAndTiers>(`${this.base_url}/transactions/my-position/${address}`);
  }

  postTransactionState(
    contractId: number,
    depositId: number,
    transactionHash: string,
    state: "inProgress" | "successful" | "reverted"
  ): Observable<any> {
    const body = {
      contractId,
      depositId,
      transactionHash,
      state,
    };
    return this.http.post(`${this.base_url}/transactions/update-state`, body);
  }

  async checkRaffleWinner(raffleSlug: string): Promise<Observable<WonResponse>> {
    return this.http.get<WonResponse>(`${this.base_url}/raffles/check-winner/${raffleSlug}`);
  }

  async updateWinnerShippingAddress(raffleSlug: string, shippingAddress: ShippingAddress): Promise<Observable<any>> {
    return this.http.post(`${this.base_url}/raffles/winner-shipping-address/${raffleSlug}`, shippingAddress);
  }

  async getRaffles(): Promise<Observable<RafflesResponse>> {
    return this.http.get<RafflesResponse>(`${this.base_url}/raffles`);
  }

  async login(wallet: WalletService): Promise<Observable<any>> {
    const signatureParams = await this.sign(wallet, "Login");
    return this.http.get<any>(`${this.base_url}/auth/web3/login`, { params: signatureParams });
  }

  private async sign(wallet: WalletService, payload: any) {
    try {
      const account = (await wallet.getAccount()) ?? "";
      const message = JSON.stringify({ payload, timestamp: new Date().getTime() }) ?? "";
      const ethSignature = (await wallet.signMessage(account!, message)) ?? "";
      const ethMessage = wallet.getMessageHash(message) ?? "";
      return { ethWallet: account, ethMessage, ethSignature };
    } catch (e: any) {
      return { ethWallet: "", ethMessage: "", ethSignature: "" };
    }
  }

  async saveSurveyResponse(surveyResponse: SurveyResponse): Promise<Observable<any>> {
    return this.http.post(`${this.base_url}/surveys/response`, surveyResponse);
  }

  async getCollectibleNft(
    type: Omit<CollectibleItemType, CollectibleItemType.COMMON>,
    address: string
  ): Promise<CollectibleNft[]> {
    return new Promise<CollectibleNft[]>((resolve, reject) => {
      this.http.get<CollectibleNftResponse>(`${this.base_url}/albums/${type}/${address}`).subscribe({
        next: (response) => {
          const itemsWithMetadata = response.items.map(async (item) => {
            if (!item.metadata && item.token_uri) {
              item.metadata = await fetch(item.token_uri).then((res) => res.json());
            }
            return item;
          });
          Promise.all(itemsWithMetadata).then((items) => resolve(items));
        },
        error: (err) => reject(err),
      });
    });
  }

  async submitCups(wallet: WalletService, cups: number) {
    return new Promise(async (resolve, reject) => {
      const signatureParams = await this.sign(wallet, { cups });
      return this.http.get<any>(`${this.base_url}/albums/submit-cups`, { params: signatureParams }).subscribe({
        next: (response) => resolve(response),
        error: (err) => reject(err),
      });
    });
  }

  async getSubmitCupsStatus(): Promise<CupsSubmitStatus> {
    return new Promise(async (resolve, reject) => {
      return this.http.get<any>(`${this.base_url}/albums/submit-cups/status`).subscribe({
        next: (response) => resolve(response.status),
        error: (err) => reject(err),
      });
    });
  }
}
