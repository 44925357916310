<div class="app-header">
  <div class="logo">
    <img src="./../../../assets/images/new/Logo.svg" alt="logo">
    <div class="menu" (click)="onToggleSideNav()">
      <img src="./../../../assets/images/new/Hamburger.svg">
    </div>
  </div>
  <div class="chainSelectorWrapper">
    <app-chain-selector></app-chain-selector>
    <app-create-staking-button></app-create-staking-button>
  </div>

  <div class="container-ranking">
   <app-ranking-widget></app-ranking-widget>
  </div>

  <div class="identifier">
    <app-connect-wallet></app-connect-wallet>
    <app-account-balance></app-account-balance>
  </div>
</div>
