import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SwapDialogComponent } from "../swap-dialog/swap-dialog.component";

@Component({
  selector: "app-swap-button",
  templateUrl: "./swap-button.component.html",
  styleUrls: ["./swap-button.component.scss"],
})
export class SwapButtonComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openUniswapDialog() {
    const dialogRef = this.dialog.open(SwapDialogComponent, {});
  }
}
