import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Chain } from "../../interfaces/contract.interface";

@Component({
  selector: "app-swap-dialog",
  templateUrl: "./swap-dialog.component.html",
  styleUrls: ["./swap-dialog.component.scss"],
})
export class SwapDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<SwapDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {}) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
