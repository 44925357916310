import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class ConnectWalletService {
  connectionRequested: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);

  constructor() {}

  requestConnection() {
    this.connectionRequested.next(true);
  }
}
