import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Chain, Contract } from "../../interfaces/contract.interface";

@Component({
  selector: "app-network-dialog",
  templateUrl: "./network-dialog.component.html",
  styleUrls: ["./network-dialog.component.scss"],
})
export class NetworkDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NetworkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { chain: Chain }
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
