<div class="general-container-sidenav">
  <div class="container-links">
    <ul class="navigation-items">
      <li routerLinkActive="menu-active" (click)="onCloseSheet()">
        <a routerLink="/staking">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="19.5" fill="#181818" stroke="#292929" />
            <path
              routerLinkActive="icon-active"
              d="M20.0963 13.3333C19.8747 13.3333 19.6622 13.421 19.5055 13.5773C19.3488 13.7336 19.2607 13.9456 19.2607 14.1666V26.6666C19.2607 26.8876 19.3488 27.0996 19.5055 27.2558C19.6622 27.4121 19.8747 27.4999 20.0963 27.4999C20.3179 27.4999 20.5304 27.4121 20.6871 27.2558C20.8438 27.0996 20.9319 26.8876 20.9319 26.6666V14.1666C20.9319 13.9456 20.8438 13.7336 20.6871 13.5773C20.5304 13.421 20.3179 13.3333 20.0963 13.3333Z"
              fill="white"
              stroke="white"
              stroke-width="0.2"
            />
            <path
              routerLinkActive="icon-active"
              d="M25.9449 20C25.7233 20 25.5108 20.0878 25.3541 20.2441C25.1974 20.4004 25.1094 20.6123 25.1094 20.8333V26.6667C25.1094 26.8877 25.1974 27.0996 25.3541 27.2559C25.5108 27.4122 25.7233 27.5 25.9449 27.5C26.1665 27.5 26.3791 27.4122 26.5358 27.2559C26.6925 27.0996 26.7805 26.8877 26.7805 26.6667V20.8333C26.7805 20.6123 26.6925 20.4004 26.5358 20.2441C26.3791 20.0878 26.1665 20 25.9449 20Z"
              fill="white"
              stroke="white"
              stroke-width="0.2"
            />
            <path
              routerLinkActive="icon-active"
              d="M14.2472 16.6667C14.0256 16.6667 13.813 16.7545 13.6564 16.9108C13.4997 17.0671 13.4116 17.2791 13.4116 17.5001V26.6667C13.4116 26.8878 13.4997 27.0997 13.6564 27.256C13.813 27.4123 14.0256 27.5001 14.2472 27.5001C14.4688 27.5001 14.6813 27.4123 14.838 27.256C14.9947 27.0997 15.0827 26.8878 15.0827 26.6667V17.5001C15.0827 17.2791 14.9947 17.0671 14.838 16.9108C14.6813 16.7545 14.4688 16.6667 14.2472 16.6667Z"
              fill="white"
              stroke="white"
              stroke-width="0.2"
            />
          </svg>
          <span routerLinkActive="menu-text-active" class="hidden-desktop menu-mobile">
            {{ "sidenav.staking" | translate }}
          </span>
        </a>
      </li>
      <li routerLinkActive="menu-active" (click)="onCloseSheet()">
        <a routerLink="/next-drops">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="19.5" fill="#141414" stroke="#1B1B1B" />
            <path
              routerLinkActive="icon-active"
              d="M25 13.3334H24.1667V12.5001C24.1667 12.2791 24.0789 12.0671 23.9226 11.9108C23.7663 11.7545 23.5543 11.6667 23.3333 11.6667C23.1123 11.6667 22.9004 11.7545 22.7441 11.9108C22.5878 12.0671 22.5 12.2791 22.5 12.5001V13.3334H17.5V12.5001C17.5 12.2791 17.4122 12.0671 17.2559 11.9108C17.0996 11.7545 16.8877 11.6667 16.6667 11.6667C16.4457 11.6667 16.2337 11.7545 16.0774 11.9108C15.9211 12.0671 15.8333 12.2791 15.8333 12.5001V13.3334H15C14.337 13.3334 13.7011 13.5968 13.2322 14.0656C12.7634 14.5345 12.5 15.1704 12.5 15.8334V25.8334C12.5 26.4965 12.7634 27.1323 13.2322 27.6012C13.7011 28.07 14.337 28.3334 15 28.3334H25C25.663 28.3334 26.2989 28.07 26.7678 27.6012C27.2366 27.1323 27.5 26.4965 27.5 25.8334V15.8334C27.5 15.1704 27.2366 14.5345 26.7678 14.0656C26.2989 13.5968 25.663 13.3334 25 13.3334ZM15 15.0001H15.8333V15.8334C15.8333 16.0544 15.9211 16.2664 16.0774 16.4227C16.2337 16.579 16.4457 16.6667 16.6667 16.6667C16.8877 16.6667 17.0996 16.579 17.2559 16.4227C17.4122 16.2664 17.5 16.0544 17.5 15.8334V15.0001H22.5V15.8334C22.5 16.0544 22.5878 16.2664 22.7441 16.4227C22.9004 16.579 23.1123 16.6667 23.3333 16.6667C23.5543 16.6667 23.7663 16.579 23.9226 16.4227C24.0789 16.2664 24.1667 16.0544 24.1667 15.8334V15.0001H25C25.221 15.0001 25.433 15.0879 25.5893 15.2442C25.7455 15.4004 25.8333 15.6124 25.8333 15.8334V19.1667H14.1667V15.8334C14.1667 15.6124 14.2545 15.4004 14.4107 15.2442C14.567 15.0879 14.779 15.0001 15 15.0001ZM25 26.6667H15C14.779 26.6667 14.567 26.579 14.4107 26.4227C14.2545 26.2664 14.1667 26.0544 14.1667 25.8334V20.8334H25.8333V25.8334C25.8333 26.0544 25.7455 26.2664 25.5893 26.4227C25.433 26.579 25.221 26.6667 25 26.6667Z"
              fill="white"
            />
          </svg>
          <span routerLinkActive="menu-text-active" class="hidden-desktop menu-mobile">
            {{ "sidenav.next-drops" | translate }}
          </span>
        </a>
      </li>
      <li routerLinkActive="menu-active" (click)="onCloseSheet()">
        <a routerLink="/nft">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="19.5" fill="#141414" stroke="#1B1B1B" />
            <path
              routerLinkActive="icon-stroke-active"
              d="M26.75 22.9999V16.9999C26.7497 16.7369 26.6803 16.4785 26.5487 16.2508C26.417 16.0231 26.2278 15.8339 26 15.7024L20.75 12.7024C20.522 12.5708 20.2633 12.5015 20 12.5015C19.7367 12.5015 19.478 12.5708 19.25 12.7024L14 15.7024C13.7722 15.8339 13.583 16.0231 13.4513 16.2508C13.3197 16.4785 13.2503 16.7369 13.25 16.9999V22.9999C13.2503 23.263 13.3197 23.5213 13.4513 23.7491C13.583 23.9768 13.7722 24.1659 14 24.2974L19.25 27.2974C19.478 27.4291 19.7367 27.4984 20 27.4984C20.2633 27.4984 20.522 27.4291 20.75 27.2974L26 24.2974C26.2278 24.1659 26.417 23.9768 26.5487 23.7491C26.6803 23.5213 26.7497 23.263 26.75 22.9999Z"
              stroke="white"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              routerLinkActive="icon-stroke-active"
              d="M13.4526 16.22L20.0001 20.0075L26.5476 16.22"
              stroke="white"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              routerLinkActive="icon-stroke-active"
              d="M20 27.56V20"
              stroke="white"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span routerLinkActive="menu-text-active" class="hidden-desktop menu-mobile">
            {{ "sidenav.nft" | translate }}
          </span>
        </a>
      </li>
      <li routerLinkActive="menu-active" (click)="onCloseSheet()">
        <a routerLink="/claims">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="19.5" fill="#141414" stroke="#1B1B1B" />
            <path
              routerLinkActive="icon-stroke-active"
              d="M20.091 22.1819C22.9026 22.1819 25.1819 19.9026 25.1819 17.091C25.1819 14.2793 22.9026 12 20.091 12C17.2793 12 15 14.2793 15 17.091C15 19.9026 17.2793 22.1819 20.091 22.1819Z"
              stroke="white"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              routerLinkActive="icon-stroke-active"
              d="M17.3346 21.3745L16.4546 28L20.091 25.8181L23.7274 28L22.8474 21.3672"
              stroke="white"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span routerLinkActive="menu-text-active" class="hidden-desktop menu-mobile">
            {{ "sidenav.claims" | translate }}
          </span>
        </a>
      </li>
      <li routerLinkActive="menu-active" (click)="onCloseSheet()">
        <a routerLink="/swap">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="19.5" fill="#141414" stroke="#1B1B1B" />
            <path
              routerLinkActive="icon-stroke-active"
              d="M16.3188 26.4762L13.0005 23.3335L16.3188 20.1907"
              stroke="white"
              stroke-width="1.8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              routerLinkActive="icon-stroke-active"
              d="M13.0002 23.3333H20.743"
              stroke="white"
              stroke-width="1.8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              routerLinkActive="icon-stroke-active"
              d="M23.6816 13.5237L27 16.6665L23.6816 19.8092"
              stroke="white"
              stroke-width="1.8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              routerLinkActive="icon-stroke-active"
              d="M27.0003 16.6666H19.2575"
              stroke="white"
              stroke-width="1.8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span routerLinkActive="menu-text-active" class="hidden-desktop menu-mobile">
            {{ "sidenav.swap" | translate }}
          </span>
        </a>
      </li>
      <li routerLinkActive="menu-active" (click)="onCloseSheet()">
        <a routerLink="/rafflenomics">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="19.5" fill="#141414" stroke="#1B1B1B" />
            <path
              routerLinkActive="icon-stroke-active"
              d="M19.9998 11.6667C18.3517 11.6667 16.7405 12.1555 15.3701 13.0712C13.9997 13.9868 12.9316 15.2883 12.3008 16.8111C11.6701 18.3338 11.5051 20.0093 11.8266 21.6258C12.1482 23.2423 12.9418 24.7272 14.1073 25.8926C15.2727 27.0581 16.7576 27.8517 18.3741 28.1733C19.9906 28.4948 21.6662 28.3298 23.1889 27.6991C24.7116 27.0683 26.0131 26.0002 26.9288 24.6298C27.8444 23.2594 28.3332 21.6483 28.3332 20.0001C28.3332 18.9057 28.1176 17.8221 27.6988 16.8111C27.28 15.8 26.6662 14.8813 25.8924 14.1075C25.1186 13.3337 24.1999 12.7199 23.1889 12.3011C22.1778 11.8823 21.0942 11.6667 19.9998 11.6667ZM19.9998 26.6667C18.6813 26.6667 17.3924 26.2758 16.296 25.5432C15.1997 24.8107 14.3452 23.7695 13.8406 22.5513C13.3361 21.3331 13.204 19.9927 13.4613 18.6995C13.7185 17.4063 14.3534 16.2184 15.2858 15.286C16.2181 14.3537 17.406 13.7187 18.6992 13.4615C19.9924 13.2043 21.3329 13.3363 22.5511 13.8409C23.7692 14.3455 24.8104 15.2 25.543 16.2963C26.2755 17.3926 26.6665 18.6815 26.6665 20.0001C26.6665 21.7682 25.9641 23.4639 24.7139 24.7141C23.4636 25.9644 21.768 26.6667 19.9998 26.6667Z"
              fill="white"
            />
            <path
              routerLinkActive="icon-stroke-active"
              d="M19.9998 17.4999C20.4601 17.4999 20.8332 17.1268 20.8332 16.6666C20.8332 16.2063 20.4601 15.8333 19.9998 15.8333C19.5396 15.8333 19.1665 16.2063 19.1665 16.6666C19.1665 17.1268 19.5396 17.4999 19.9998 17.4999Z"
              fill="white"
            />
            <path
              routerLinkActive="icon-stroke-active"
              d="M19.9998 18.3333C19.7788 18.3333 19.5669 18.421 19.4106 18.5773C19.2543 18.7336 19.1665 18.9456 19.1665 19.1666V23.3333C19.1665 23.5543 19.2543 23.7662 19.4106 23.9225C19.5669 24.0788 19.7788 24.1666 19.9998 24.1666C20.2209 24.1666 20.4328 24.0788 20.5891 23.9225C20.7454 23.7662 20.8332 23.5543 20.8332 23.3333V19.1666C20.8332 18.9456 20.7454 18.7336 20.5891 18.5773C20.4328 18.421 20.2209 18.3333 19.9998 18.3333Z"
              fill="white"
            />
          </svg>
          <span routerLinkActive="menu-text-active" class="hidden-desktop menu-mobile">
            {{ "sidenav.rafflenomics" | translate }}
          </span>
        </a>
      </li>
      <li>
        <mat-form-field appearance="fill" style="background-color: #141414">
          <mat-label class="language" style="background-color: #141414">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="19.5" fill="#181818" stroke="#292929" />
              <path
                routerLinkActive="icon-stroke-active"
                d="M20 12C15.5887 12 12 15.5887 12 20C12 24.4113 15.5888 28 20 28C24.4112 28 28 24.4112 28 20C28 15.5887 24.4111 12 20 12ZM16.262 20C16.262 19.0058 16.3827 18.0546 16.5939 17.1889H19.5164V22.8112H16.5939C16.3827 21.9454 16.262 20.9944 16.262 20.0001V20ZM20.4836 13.0913C21.5731 13.3681 22.5302 14.5562 23.1196 16.2213H20.4836V13.0913ZM19.5164 13.0913V16.2213H16.8804C17.4697 14.5562 18.4269 13.3681 19.5164 13.0913ZM19.5164 23.7782V26.9083C18.4269 26.6315 17.4698 25.4434 16.8804 23.7782H19.5164ZM20.4836 26.9085V23.7782H23.1196C22.5302 25.4435 21.5731 26.6316 20.4836 26.9085ZM20.4836 22.8109V17.1887H23.406C23.6172 18.0544 23.738 19.0055 23.738 19.9998C23.738 20.994 23.6172 21.9451 23.406 22.8109L20.4836 22.8109ZM24.4071 17.1887H26.4425C26.82 18.0505 27.0327 19.0003 27.0327 19.9998C27.0327 20.9993 26.82 21.949 26.4425 22.8109H24.4071C24.597 21.9394 24.7053 20.9959 24.7053 19.9998C24.7053 19.0037 24.597 18.06 24.4071 17.1887ZM25.9219 16.2213H24.1562C23.8192 15.1469 23.3385 14.2316 22.7574 13.5313C24.0637 14.0904 25.1631 15.0364 25.9219 16.2213ZM17.2426 13.5313C16.6615 14.2316 16.181 15.1469 15.8438 16.2213H14.0781C14.8369 15.0364 15.9362 14.0904 17.2426 13.5313H17.2426ZM13.5575 17.1887H15.593C15.403 18.0602 15.2947 19.0036 15.2947 19.9998C15.2947 20.9959 15.403 21.9396 15.593 22.8109H13.5575C13.18 21.949 12.9673 20.9993 12.9673 19.9998C12.9671 19.0001 13.1798 18.0505 13.5575 17.1887ZM14.0781 23.7783H15.8438C16.1809 24.8527 16.6615 25.768 17.2426 26.4682C15.9363 25.9092 14.8369 24.9632 14.0782 23.7783H14.0781ZM22.7574 26.4682C23.3386 25.768 23.819 24.8527 24.1562 23.7783H25.9219C25.1632 24.9632 24.0638 25.9092 22.7575 26.4682H22.7574Z"
                fill="white"
              />
            </svg>
            <span routerLinkActive="menu-text-active" class="hidden-desktop language-select">
              {{ "sidenav.language" | translate }}
            </span>
          </mat-label>
          <mat-select class="language-menu">
            <mat-option>
              <button mat-menu-item (click)="languageChange(0)" (click)="onCloseSheet()">
                <img src="./../../../assets/images/english.svg" alt="" /> EN
              </button>
              <button mat-menu-item (click)="languageChange(1)" (click)="onCloseSheet()">
                <img src="./../../../assets/images/spanish.svg" alt="" /> ES
              </button>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
    </ul>
  </div>
</div>
