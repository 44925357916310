export class TranslationSet {
  public language: string;
  public values: { [key: string]: string | {} } = {};
}

import { Injectable } from "@angular/core";

// Import i18n json
import eng from "../i18n/en-EN.json";
import spa from "../i18n/es-ES.json";

interface TranslationParams {
  [key: string]: string;
}
@Injectable({ providedIn: "root" })
export class TranslationService {
  public languages: string[] = ["en", "es"];
  public language: string = "en";
  private dictionary: { [key: string]: TranslationSet } = {
    en: eng,
    es: spa,
  };

  constructor() {
    let aux = this.languages.filter((element) => element == navigator.language.split("-")[0]);
    this.language = aux.length > 0 ? aux[0] : "en";
  }

  // translate(key: string):string {
  //     if (this.dictionary[this.language] != null) {
  //         return this.dictionary[this.language].values[key] as string;
  //     }
  //     return key;
  // }

  translate(key: string, args?: TranslationParams): string {
    let auxString: string = key;
    if (this.dictionary[this.language] != null) {
      // return this.dictionary[this.language].values[key] as string;
      const keys = key.split(".");
      let aux: any = this.dictionary[this.language].values;
      keys.forEach((element) => {
        if (typeof aux[element] == "undefined") {
          auxString = key;
        } else {
          if (typeof aux[element] == "string") {
            auxString = aux[element];
          } else {
            aux = aux[element];
          }
        }
      });
    }
    const translation = this.replaceParams(auxString, args);
    return translation;
  }

  replaceParams(stringWithParams: string, paramsValues?: TranslationParams): string {
    let replacedString = stringWithParams;
    if (paramsValues) {
      for (const param in paramsValues) {
        if (!paramsValues.hasOwnProperty(param)) {
          continue;
        }
        replacedString = replacedString.replace(
          new RegExp("\\{\\{\\s*" + param + "\\s*\\}\\}", "g"),
          paramsValues[param]
        );
      }
    }
    return replacedString;
  }
}
