import { TranslationService } from "../services/translate.service";

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "translate",
  pure: false,
})
export class TranslatePipe implements PipeTransform {
  constructor(private _translation: TranslationService) {}

  transform(value: any, ...args: any[]): any {
    return this._translation.translate(value, args && args.length > 0 ? args[0] : undefined);
  }
}
