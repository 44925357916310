import { Component, OnInit } from "@angular/core";
import { Chain } from "../../interfaces/contract.interface";
import { DataService } from "../../services/data.service";
import { StakingDialogComponent } from "../staking-dialog/staking-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { WalletService } from "../../services/wallet.service";
import { ApiService } from "../../services/api.service";
import { SpinnerService } from "../../services/spinner.service";
import { LoaderDialogComponent } from "../loader-dialog/loader-dialog.component";
import { NetworkDialogComponent } from "../network-dialog/network-dialog.component";

@Component({
  selector: "app-create-staking-button",
  templateUrl: "./create-staking-button.component.html",
  styleUrls: ["./create-staking-button.component.scss"],
})
export class CreateStakingButtonComponent implements OnInit {
  selectedChain: Chain;
  account: string;

  constructor(
    public dialog: MatDialog,
    private _data: DataService,
    private _wallet: WalletService,
    private _api: ApiService,
    private _spinner: SpinnerService
  ) {}

  ngOnInit(): void {
    this._data.account.subscribe({
      next: async (res) => {
        if (res) {
          this.account = res;
        }
      },
    });
    this._data.selectedChain.subscribe({
      next: (chain) => {
        this.selectedChain = chain;
      },
    });
  }

  openDialog() {
    const chain = this.selectedChain;
    const dialogRef = this.dialog.open(StakingDialogComponent, {
      data: {
        chain,
        account: this.account,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (res.action == "stake") {
          this.stake(res).then();
        } else if (res.action == "mint") {
          this.mint(res).then();
        } else if (res.action == "approve") {
          this.approve(res).then();
        }
      }
    });
  }

  async stake(data: any) {
    if (await this._wallet.correctNetwork(data.chain.id)) {
      this.openLoaderTransactionModal();
      try {
        await this._wallet.staking(data.chain.id, data.amount, data.period, data.account);
        this._data.emitCheckTransactionsEvent();
      } catch {}
      this.closeLoaderTransactionModal();
    } else {
      this.openCorrectNetworkModal(data.chain);
    }
  }

  async mint(data: any) {
    if (await this._wallet.correctNetwork(data.chain.id)) {
      this.openLoaderTransactionModal();
      try {
        await this._wallet.mint(data.chain.id, data.account);
        this.closeLoaderTransactionModal();
        this.openDialog();
      } catch {
        this.closeLoaderTransactionModal();
      }
    } else {
      this.openCorrectNetworkModal(data.chain);
    }
  }

  async approve(data: any) {
    if (await this._wallet.correctNetwork(data.chain.id)) {
      this.openLoaderTransactionModal();
      try {
        await this._wallet.approve(data.chain.id);
        this.closeLoaderTransactionModal();
        this.openDialog();
      } catch {
        this.closeLoaderTransactionModal();
      }
    } else {
      this.openCorrectNetworkModal(data.chain);
    }
  }

  openLoaderTransactionModal() {
    this.dialog.open(LoaderDialogComponent, {
      disableClose: true,
      width: "480px",
      height: "200px",
    });
  }

  closeLoaderTransactionModal() {
    this.dialog.closeAll();
  }

  openCorrectNetworkModal(chain: Chain) {
    this.dialog.open(NetworkDialogComponent, {
      data: { chain },
    });
  }
}
