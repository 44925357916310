import { Component, OnInit } from "@angular/core";
import { WalletService } from "../../services/wallet.service";
import { DataService } from "../../services/data.service";
import { ApiService } from "../../services/api.service";
import { Chain } from "../../interfaces/contract.interface";

@Component({
  selector: "app-chain-selector",
  templateUrl: "./chain-selector.component.html",
  styleUrls: ["./chain-selector.component.scss"],
})
export class ChainSelectorComponent implements OnInit {
  loadedContract: boolean = false;
  account: string;
  chains: Chain[] = [];
  selectedChain: Chain;
  constructor(private _wallet: WalletService, private _data: DataService, private _api: ApiService) {}

  ngOnInit(): void {
    this._data.account.subscribe({
      next: async (res) => {
        if (res) {
          this.account = res;
          if (this.loadedContract) {
            await this.loadData();
          } else {
            this._data.loadedContract.subscribe({
              next: async (res) => {
                this.loadedContract = res;
                await this.loadData();
              },
            });
          }
        }
      },
    });
    this._data.selectedChain.subscribe({
      next: (chain) => {
        this.selectedChain = chain;
      },
    });
  }

  async loadData() {
    if (this.loadedContract) {
      this.chains.splice(0, this.chains.length);
      const currentChainId = await this._wallet.getCurrentChainId();
      const stakingContracts = await this._api.getStakingContracts();
      const nftStakingContracts = await this._api.getNftStakingContracts();
      const contracts = [...nftStakingContracts];
      stakingContracts.forEach((contract) => {
        if (!contracts.find((c) => c.chainId === contract.chainId)) {
          contracts.push(contract);
        }
      });
      contracts.forEach((contract) => {
        this.chains.push(contract.chain);
        if (contract.chainId == currentChainId) {
          this._data.setSelectedChain(contract.chain);
        }
      });
    }
  }

  selectChain(chain: Chain): void {
    this._data.setSelectedChain(chain);
  }
}
