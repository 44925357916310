import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { MaterialModule } from "./modules/material/material.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TestDialogComponent } from "./components/test-dialog/test-dialog.component";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { WalletsDialogComponent } from "./components/header/wallets_dialog/wallets_dialog.component";
import { ReactiveFormsModule } from "@angular/forms";
import { DecimalPipe } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoaderDialogComponent } from "./components/loader-dialog/loader-dialog.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { NumericDirective } from "./directives/numeric.directive";
import { NetworkDialogComponent } from "./components/network-dialog/network-dialog.component";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { UniswapDialogComponent } from "./components/uniswap-dialog/uniswap-dialog.component";
import { UniswapModule } from "uniswap-angular";
import { SwapComponent } from "./components/swap/swap.component";
import { AuthInterceptor } from "./http-interceptors/auth-interceptor";
import { ConnectWalletComponent } from "./components/connect-wallet/connect-wallet.component";
import { AccountBalanceComponent } from "./components/account-balance/account-balance.component";
import { ChainSelectorComponent } from "./components/chain-selector/chain-selector.component";
import { CreateStakingButtonComponent } from "./components/create-staking-button/create-staking-button.component";
import { ShiTokenMarqueeComponent } from "./components/shi-token-marquee/shi-token-marquee.component";
import { RankingWidgetComponent } from "./components/ranking-widget/ranking-widget.component";
import { SwapButtonComponent } from "./components/swap-button/swap-button.component";
import { SwapDialogComponent } from "./components/swap-dialog/swap-dialog.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    TestDialogComponent,
    WalletsDialogComponent,
    LoaderDialogComponent,
    NumericDirective,
    NetworkDialogComponent,
    SidenavComponent,
    UniswapDialogComponent,
    SwapComponent,
    ConnectWalletComponent,
    AccountBalanceComponent,
    ChainSelectorComponent,
    CreateStakingButtonComponent,
    ShiTokenMarqueeComponent,
    RankingWidgetComponent,
    SwapButtonComponent,
    SwapDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatProgressBarModule,
    UniswapModule,
  ],
  providers: [
    FormControl,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    DecimalPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  entryComponents: [SidenavComponent],
  bootstrap: [AppComponent],
  exports: [UniswapModule],
})
export class AppModule {}
