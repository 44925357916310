export enum ContractType {
  NFT = "NFT",
  STAKING = "STAKING",
  NFTSTAKING = "NFTSTAKING",
  TOKEN = "TOKEN",
  REWARDS = "REWARDS",
  NFT_COLLECTIBLE = "NFT_COLLECTIBLE",
}

export interface Contract {
  id: number;
  chainId: number;
  chain: Chain;
  abiName: string;
  address: string;
  contractType: ContractType;
}

export interface Chain {
  id: number;
  name: string;
  imageUrl: string;
  rpcProviderUrl: string;
  contracts: Contract[];
}
