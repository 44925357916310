<div class="ranking-widget" *ngIf="ranking">
  <div class="top" >
    <div class="upLvl"><img src="../../assets/images/new/ArrowUp.svg"><span class="specialChar">$shi </span>{{ranking.upgradeAmount | number: "1.0-2"}}</div>
    <div class="tier">tier <span>{{ranking.currentTierLevel}}</span></div>
  </div>
  <div class="mid">
    <div class="rank" *ngIf="ranking.ranking>0">rank <span><span class="specialChar">#</span>{{ranking.ranking}}</span></div>
  </div>
  <div class="bot">
    <div class="downLvl"><img src="../../assets/images/new/ArrowDown.svg"><span class="specialChar">$shi </span>{{ranking.downgradeAmount | number: "1.0-2"}}</div>
  </div>
</div>
