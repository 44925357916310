import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class AuthService {
  hasAccessToken(): boolean {
    return this.getAccessToken() != null;
  }

  saveAccessToken(accessToken: string) {
    sessionStorage.setItem("access_token", accessToken);
  }

  getAccessToken(): string | null {
    return sessionStorage.getItem("access_token");
  }

  removeAccessToken() {
    sessionStorage.removeItem("access_token");
  }

  saveAddress(address: string) {
    sessionStorage.setItem("address", address);
  }

  getAddress(): string | null {
    return sessionStorage.getItem("address");
  }

  removeAddress() {
    sessionStorage.removeItem("address");
  }
}
