<div class="general-container">
  <div class="container-header">
    <h2 mat-dialog-title>
      {{"network-dialog.title" | translate}}
      <!-- <span>#8520</span> -->
    </h2>
    <mat-icon aria-hidden="false" aria-label="Close Icon" class="close_icon" (click)="close()">highlight_off</mat-icon>
  </div>

  <mat-dialog-content>
    <span>{{ "network-dialog.content.text" | translate: { name : data.chain?.name } }}</span>
  </mat-dialog-content>
</div>
