<div class="general-container">
  <div class="swap-container">
    <lib-uniswap-angular-swapper
    *ngIf="uniswapDappSharedLogicContext"
    [uniswapDappSharedLogicContext]="uniswapDappSharedLogicContext"
  ></lib-uniswap-angular-swapper>

  <p class="powered">
    Powered by
    <a href="https://uniswap.org/" target="_blank">Uniswap.com</a>
  </p>
  <p>
    Or try
    <a href="https://app.uniswap.org/#/swap?chain=rinkeby" target="_blank">Uniswap</a>
  </p>
  </div>
</div>