import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Chain } from "../interfaces/contract.interface";
import { CollectibleNft } from "../interfaces/collectible-nft.interface";

@Injectable({ providedIn: "root" })
export class DataService {
  selectedChain: BehaviorSubject<any> = new BehaviorSubject<Chain | undefined>(undefined);
  account: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  loadedContract: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  checkTransactions: BehaviorSubject<any> = new BehaviorSubject<boolean>(true);
  nftCollectibleUpdated: BehaviorSubject<any> = new BehaviorSubject<CollectibleNft | undefined>(undefined);

  constructor() {}

  setAccount(account: string) {
    const currentAccount: string = this.account.getValue() || "";
    if (currentAccount.toLowerCase() !== account.toLowerCase()) {
      this.account.next(account);
    }
  }

  setNotAccount() {
    this.account.next(undefined);
  }

  emitCheckTransactionsEvent() {
    this.checkTransactions.next(true);
  }

  setLoadedContract(load: boolean) {
    this.loadedContract.next(load);
  }

  setSelectedChain(chain: Chain) {
    const currentChainId: number = this.selectedChain?.getValue() || 0;
    if (currentChainId !== chain.id) {
      this.selectedChain.next(chain);
    }
  }

  postNftCollectibleUpdate(updated: CollectibleNft) {
    this.nftCollectibleUpdated.next(updated);
  }
}
