import { Component, OnInit } from "@angular/core";
import { CoingeckoService } from "../../services/coingecko.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-shi-token-marquee",
  templateUrl: "./shi-token-marquee.component.html",
  styleUrls: ["./shi-token-marquee.component.scss"],
})
export class ShiTokenMarqueeComponent implements OnInit {
  coingecko: string;

  constructor(private _coingeckoService: CoingeckoService) {}

  ngOnInit(): void {
    this.getCurrencyInfo();
  }

  getCurrencyInfo() {
    this._coingeckoService.getCurrency().subscribe(
      (resp) => {
        this.coingecko = resp.shirtum.usd;
      },
      (err: HttpErrorResponse) => {
        console.log("error", err);
      }
    );
  }
}
