import { Component, OnInit } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { WalletService } from "../../services/wallet.service";
import { MatDialog } from "@angular/material/dialog";
import { DataService } from "../../services/data.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConnectWalletService } from "../../services/connect-wallet.service.ts.service";
import { TranslationService } from "../../services/translate.service";
import { ApiService } from "../../services/api.service";
import { environment } from "../../../environments/environment";
import { WalletsDialogComponent } from "../header/wallets_dialog/wallets_dialog.component";
import { NetworkDialogComponent } from "../network-dialog/network-dialog.component";

@Component({
  selector: "app-connect-wallet",
  templateUrl: "./connect-wallet.component.html",
  styleUrls: ["./connect-wallet.component.scss"],
})
export class ConnectWalletComponent implements OnInit {
  account?: string;
  correctNetwork: boolean;
  language: number;

  constructor(
    private clipboard: ClipboardService,
    private _wallet: WalletService,
    private dialog: MatDialog,
    private _data: DataService,
    private snackbar: MatSnackBar,
    private _connectWallet: ConnectWalletService,
    private _translate: TranslationService,
    private _api: ApiService
  ) {}

  async ngOnInit(): Promise<void> {
    await this._wallet.getAccount();
    this.language = this._translate.languages.findIndex((element) => element == this._translate.language);
    this._data.account.subscribe({
      next: async (res) => {
        this.account = res;
      },
    });
    this._connectWallet.connectionRequested.subscribe({
      next: (res) => {
        if (res) {
          this.connectWallet();
        }
      },
    });
  }
  checkNetwork(): boolean {
    if (environment.production) {
      return this._wallet.networkId == 1 ? true : false;
    } else {
      return this._wallet.networkId == 4 ? true : false;
    }
  }

  copyToClipboard() {
    this.clipboard.copyFromContent(this.account as string);
    this.snackbar.open("Address copied to clipboard", "", {
      verticalPosition: "top",
      panelClass: "center",
      duration: 3000,
    });
  }

  connectWallet() {
    const innerWidth = window.innerWidth;
    const dialog = this.dialog.open(WalletsDialogComponent, {
      width: innerWidth < 425 ? "342px" : "520px",
      height: "335px",
    });
    dialog.afterClosed().subscribe({
      next: (res: any) => {
        if (res === "metamask") {
          this.connectWithMetamask();
        } else if (res === "install-metamask") {
          window.open("https://metamask.io/", "_blank");
        } else if (res === "walletConnect") {
          this.connectWithWalletConnect();
        }
      },
      error: (err: any) => {},
      complete: () => {},
    });
  }

  async connectWithMetamask() {
    if (!this._wallet.isMetamaskInstalled()) {
      return console.log("Metamask is not installed");
    }
    await this._wallet.connectWithMetamask().then(async (res: any) => {
      console.log("Address --> ", res);
      this.account = res;
      console.log(this._wallet.networkId);
      // await this.getAccount();
      // this._account.logged();
    });
  }

  async connectWithWalletConnect() {
    await this._wallet.connectWithWalletConnect().then(async (res: any) => {
      console.log("Address --> ", res);
      this.account = res;
      // await this.getAccount();
      // this._account.logged();
    });
  }

  openCorrectNetworkModal() {
    this.dialog.open(NetworkDialogComponent, {
      data: { isProd: environment.production },
    });
  }

  languageChange(event: number) {
    this.language = event;
    this._translate.language = this._translate.languages[event];
  }
}
