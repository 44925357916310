import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "test-dialog",
  templateUrl: "test-dialog.component.html",
})
export class TestDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<TestDialogComponent>) {}

  ngOnInit() {}
}
