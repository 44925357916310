import { Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
import { MatSidenav } from "@angular/material/sidenav";
import { ApiService } from "./services/api.service";
import { DataService } from "./services/data.service";
import { SpinnerService } from "./services/spinner.service";
import { WalletService } from "./services/wallet.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { SidenavComponent } from "./components/sidenav/sidenav.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  title = "staking-v2";
  account: string;
  showSpinner: boolean = false;
  mode: ProgressSpinnerMode = "indeterminate";
  value = 100;

  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  constructor(
    public dialog: MatDialog,
    private _wallet: WalletService,
    private _data: DataService,
    private _api: ApiService,
    private _spinner: SpinnerService,
    private observer: BreakpointObserver,
    private bottomSheet: MatBottomSheet
  ) {}

  openBottomSheet() {
    this.bottomSheet.open(SidenavComponent);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    if (event.target.innerWidth > 767) {
      this.sidenav.mode = "side";
      this.sidenav.open();
    } else {
      this.sidenav.close();
      this.sidenav.mode = "over";
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (window.innerWidth > 767) {
        this.sidenav.mode = "side";
        this.sidenav.open();
      } else {
        this.sidenav.close();
        this.sidenav.mode = "over";
      }
    }, 0);
  }

  ngOnInit(): void {
    this._spinner.loading.subscribe({
      next: (resp) => {
        this.showSpinner = resp;
      },
    });
    const cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#000",
          border: "gray",
        },
        button: {
          background: "#00e13a",
          text: "#000",
        },
      },
      theme: "classic",
      content: {
        message:
          "We use cookies to ensure that we give you the best experience on our website. If you continue to use this site, we will assume that you agree with it.",
        dismiss: "OK",
        link: "",
      },
    });
  }

  ngOnDestroy(): void {
    this._data.account.unsubscribe();
  }

  toggleSideNav(): void {
    this.sidenav.toggle();
  }
}
