import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class SpinnerService {
  public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() {}

  open() {
    this.loading.next(true);
  }

  close() {
    this.loading.next(false);
  }
}
