<div class="wrapper">
  <div class="overlay" *ngIf="showSpinner">
    <div class="spinner-wrapper">
      <mat-progress-spinner
        *ngIf="showSpinner"
        diameter="40"
        style="margin: 0 auto"
        [mode]="mode"
        [value]="value"
        class="example-margin"
      >
      </mat-progress-spinner>
    </div>
  </div>



    <!--button mat-icon-button class="hidden-desktop" (click)="openBottomSheet()">
      <mat-icon>menu</mat-icon>
    </button -->


  <mat-sidenav-container>
    <mat-sidenav #sidenav="matSidenav">
      <div class="navigator">
        <div class="header">
          <div class="logo">
            <img src="./../../../assets/images/new/Logo.svg" alt="logo">
            <div class="menu" (click)="toggleSideNav()">
              <img src="./../../../assets/images/new/Plus.svg">
            </div>
          </div>
          <div class="info">
            <app-ranking-widget></app-ranking-widget>
          </div>
          <div class="btn" routerLinkActive="active" routerLink="/staking">
            <img src="../assets/images/new/Stat.svg" alt="chart">
            <div class="text">{{ "sidenav.staking" | translate }}</div>
          </div>
          <div class="btn"  routerLinkActive="active" routerLink="/album">
            <img src="../assets/images/new/Album.svg" alt="chart">
            <div class="text">{{ "sidenav.album" | translate }}</div>
          </div>
        </div>
        <div class="info balance">
          <app-connect-wallet></app-connect-wallet>
          <app-account-balance></app-account-balance>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <app-header (sidenavToggle)="toggleSideNav()"></app-header>
      <div class="main">
        <div class="content-page">
          <!--div class="brand">
              <app-shi-token-marquee></app-shi-token-marquee>
          </div-->
          <!-- <app-header class="header"></app-header> -->
          <router-outlet> </router-outlet>
        </div>
        <app-footer></app-footer>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
