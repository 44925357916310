import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import Web3 from "web3";
import BN from "bn.js";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Period } from "../interfaces/periods.interface";
import { Subject } from "rxjs";
import { DataService } from "./data.service";
import { ApiService } from "./api.service";
import { Chain, Contract, ContractType } from "../interfaces/contract.interface";
import { CollectibleNftMetadata } from "../interfaces/collectible-nft.interface";

declare global {
  interface Window {
    ethereum: any;
    BinanceChain: any;
  }
}

// Import Contacts
const rewardContract = require("src/contracts/RewardStake.json");
const stakeContract = require("src/contracts/ShirtumStakeV2.json");
const testTokenContract = require("src/contracts/TestSHI.json");
const tokenContract = require("src/contracts/ShiToken.json");
const nftstakeContract = require("src/contracts/GenesisNFT.json");
const nftCollectiblesContract = require("src/contracts/ShirtumCollectible.json");

// Las redes si fuera necesario
/*
Rinkeby: https://rinkeby.infura.io/v3/aa099d556f6a44b1919a2662f117060b
Mainnet: https://mainnet.infura.io/v3/aa099d556f6a44b1919a2662f117060b
*/

const ABIS: { [key: string]: any } = {
  ShirtumStakeV2: stakeContract.abi,
  RewardStake: rewardContract.abi,
  ShiToken: tokenContract.abi,
  GenesisNFT: nftstakeContract.abi,
  TestSHI: testTokenContract.abi,
  ShirtumCollectible: nftCollectiblesContract.abi,
};

export interface ContractChainAbi {
  contractEntity: Contract;
  chain: Omit<Chain, "contracts">;
  abi: any;
  contract: any;
  readOnlyContract: any;
}

interface ChainPeriods {
  chain: Chain;
  allPeriods: Period[];
}

@Injectable({ providedIn: "root" })
export class WalletService {
  web3: any;
  web3Infura: any;
  account: string;
  networkId: number;

  nftCollectibleContracts: ContractChainAbi[] = [];
  tokenContracts: ContractChainAbi[] = [];
  stakeContracts: ContractChainAbi[] = [];
  nftStakeContracts: ContractChainAbi[] = [];
  mixedStakeContracts: ContractChainAbi[] = [];
  rewardsContracts: ContractChainAbi[] = [];
  periods: ChainPeriods[] = [];

  transactionHash: Subject<string> = new Subject<string>();
  loadingApprove: Subject<string> = new Subject<string>();

  constructor(private _data: DataService, private _api: ApiService) {
    this.init();
  }

  async init(): Promise<void> {
    this.web3 = this.getReadOnlyWeb3();
    if (window.ethereum) {
      window.ethereum.on("networkChanged", function (networkId: any) {
        console.log("networkChanged", networkId);
        window.location.reload();
      });
      window.ethereum.on("accountsChanged", (accounts: string[]) => {
        console.log("accountsChanged", accounts);
        if (accounts.length > 0) {
          this._data.setAccount(accounts[0]);
          this.account = accounts[0];
        } else {
          this._data.setNotAccount();
        }
      });
      this.web3 = new Web3(window.ethereum);

      // const account = await this.getAccount();
    }
    const chainsWithActiveContracts = await this._api.getChainWithActiveContracts();
    await this.loadActiveContracts(chainsWithActiveContracts);
    await this.getPeriodsForChains(chainsWithActiveContracts);
    this._data.setLoadedContract(true);
  }

  async getCurrentChainId(): Promise<number> {
    try {
      return await this.web3.eth.net.getId();
    } catch (e) {
      return 0;
    }
  }

  async correctNetwork(chainId: number): Promise<boolean> {
    const networkId = await this.web3.eth.net.getId();
    return chainId === networkId;
  }

  // Start Web3 Methods

  getReadOnlyWeb3(): any {
    if (!this.web3Infura) {
      let provider =
        // 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161';
        "http://localhost:7545"; //una vez el contrato esté deployado en rinkeby entonces si
      if (environment.production) {
        provider = "https://mainnet.infura.io/v3/a8ef08c3916542bebb40be254d8072b2";
      }
      this.web3Infura = new Web3(new Web3.providers.HttpProvider(provider));
    }
    return this.web3Infura;
  }

  getMessageHash(message: string) {
    return this.web3.utils.fromUtf8(message);
  }

  async signMessage(account: string, message: string): Promise<string> {
    const msg = this.getMessageHash(message);
    const signed = await this.web3.eth.personal.sign(msg, account);
    return signed;
  }

  isMetamaskInstalled(): boolean {
    if (window.ethereum) {
      return true;
    }
    return false;
  }

  async connectWithMetamask(): Promise<string> {
    this.web3 = new Web3(window.ethereum);

    return new Promise((resolve, reject) => {
      window.ethereum.enable().then(async (account: string[]) => {
        if (account !== null) {
          resolve(account[0]);
        }

        reject("No accounts found");
      });
    });
  }

  async connectWithWalletConnect(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const provider = new WalletConnectProvider({
        infuraId: "a8ef08c3916542bebb40be254d8072b2",
        chainId: environment.production ? 1 : 4,
      });

      this.web3 = new Web3(provider as any);

      provider["on"]("accountsChanged", (accounts: string[]) => {
        if (accounts.length > 0) {
          this._data.setAccount(accounts[0]);
          this.account = accounts[0];
        } else {
          this._data.setNotAccount();
        }
      });

      await provider.enable();
    });
  }

  async getAccount(): Promise<string | undefined> {
    if (!this.web3) {
      return undefined;
    }

    const accounts = await this.web3.eth.getAccounts();
    if (accounts.length === 0) {
      return undefined;
    }
    this.account = accounts[0];
    this._data.setAccount(accounts[0]);
    return accounts[0];
  }

  // Finish Web3 Methods

  // Start Token Contract Methods

  getContractChainAbiByChainId(contracts: ContractChainAbi[], chainId: number) {
    return contracts.find((item) => item.chain.id === chainId);
  }

  getContractsByChainId(contracts: ContractChainAbi[], chainId: number) {
    const { contract, readOnlyContract, contractEntity } = this.getContractChainAbiByChainId(contracts, chainId) ?? {};
    return { contract, readOnlyContract, contractEntity };
  }

  getTokenContractForChainId(chainId: number) {
    return this.getContractsByChainId(this.tokenContracts, chainId);
  }

  getStakingContractForChainId(chainId: number) {
    return this.getContractsByChainId(this.mixedStakeContracts, chainId);
  }

  getRewardsContractForChainId(chainId: number) {
    return this.getContractsByChainId(this.rewardsContracts, chainId);
  }

  async getPeriodsForChains(chainsWithActiveContracts: Chain[]) {
    this.periods = await Promise.all(
      chainsWithActiveContracts.map(async (chain) => {
        const allPeriods = await this.getPeriods(chain.id);
        return { chain, allPeriods };
      })
    );
  }

  async getPeriods(chainId: number): Promise<Period[]> {
    const periods = await this.getAllLockingPeriodsDetails(chainId);
    return periods.map((period: any, index: number) => {
      if (typeof period === "string") {
        const periodData = period.split(";");
        return {
          enabled: periodData[3] === "1",
          lockingTime: periodData[1],
          periodId: periodData[0],
          taxBenefit: periodData[2],
        };
      } else {
        return {
          enabled: period.enabled,
          lockingTime: period.lockingTime,
          taxBenefit: period.value,
          periodId: index + 1,
        };
      }
    });
  }

  getChainPeriods(): ChainPeriods[] {
    return this.periods;
  }

  async loadActiveContracts(chainsWithActiveContracts: Chain[]) {
    chainsWithActiveContracts.forEach((chainsWithActiveContract) => {
      const { contracts, ...chain } = chainsWithActiveContract;
      chainsWithActiveContract.contracts.forEach((contractEntity) => {
        const abi = ABIS[contractEntity.abiName];
        if (abi !== undefined) {
          const contract = new this.web3.eth.Contract(abi, contractEntity.address);
          const web3Client = new Web3(new Web3.providers.HttpProvider(chain.rpcProviderUrl));
          const readOnlyContract = new web3Client.eth.Contract(abi, contractEntity.address);
          switch (contractEntity.contractType) {
            case ContractType.TOKEN:
              this.tokenContracts.push({ chain, contractEntity, abi, contract, readOnlyContract });
              break;
            case ContractType.STAKING:
              this.stakeContracts.push({ chain, contractEntity, abi, contract, readOnlyContract });
              break;
            case ContractType.REWARDS:
              this.rewardsContracts.push({ chain, contractEntity, abi, contract, readOnlyContract });
              break;
            case ContractType.NFTSTAKING:
              this.nftStakeContracts.push({ chain, contractEntity, abi, contract, readOnlyContract });
              this.mixedStakeContracts.push({ chain, contractEntity, abi, contract, readOnlyContract });
              break;
            case ContractType.NFT_COLLECTIBLE:
              this.nftCollectibleContracts.push({ chain, contractEntity, abi, contract, readOnlyContract });
          }
        }
      });

      this.stakeContracts.forEach((stakeContract) => {
        if (!this.mixedStakeContracts.find((c) => c.chain.id === stakeContract.chain.id)) {
          this.mixedStakeContracts.push(stakeContract);
        }
      });
    });
  }

  async allowance(chainId: number): Promise<BN> {
    const stakeContractChainAbi = this.getContractChainAbiByChainId(this.mixedStakeContracts, chainId);
    if (stakeContractChainAbi) {
      return this.allowanceForContract(chainId, stakeContractChainAbi.contractEntity.address);
    }
    return new BN("0");
  }

  async allowanceForContract(chainId: number, contractAddress: string): Promise<BN> {
    const tokenContracts = this.getTokenContractForChainId(chainId);
    const { readOnlyContract: tokenContract } = tokenContracts;
    if (tokenContract) {
      return this.web3.utils.toBN(await tokenContract.methods.allowance(this.account, contractAddress).call());
    }
    return new BN("0");
  }

  async approve(
    chainId: number,
    amount = "115792089237316195423570985008687907853269984665640564039457584007913129639935"
  ): Promise<any> {
    const stakeContractChainAbi = this.getContractChainAbiByChainId(this.mixedStakeContracts, chainId);
    if (stakeContractChainAbi) {
      await this.approveForContract(chainId, stakeContractChainAbi?.contractEntity.address, amount);
    }
  }

  async approveForContract(
    chainId: number,
    contractAddress: string,
    amount = "115792089237316195423570985008687907853269984665640564039457584007913129639935"
  ): Promise<void> {
    const tokenContracts = this.getTokenContractForChainId(chainId);
    const { contract: tokenContract, contractEntity } = tokenContracts;
    if (tokenContract) {
      await tokenContract.methods
        .approve(contractAddress, amount)
        .send({ from: this.account }, (error: any, transactionHash: any) => {
          this.loadingApprove.next(transactionHash);
        });
    }
  }

  async getUserBalance(chainId: number, address: string): Promise<string> {
    const userBalance = await this._getUserBalance(chainId, address);
    return this.web3.utils.fromWei(userBalance);
  }

  async _getUserBalance(chainId: number, address: string): Promise<BN> {
    const tokenContracts = this.getTokenContractForChainId(chainId);
    const { readOnlyContract: tokenContract } = tokenContracts;
    try {
      if (tokenContract) {
        const balanceOf = await tokenContract.methods.balanceOf(address).call();
        return this.web3.utils.toBN(balanceOf);
      } else {
        console.log("no tokenContract for chainId:", chainId);
      }
    } catch (e: any) {
      console.log(e.message);
    }
    return new BN("0");
  }

  async getTotalUserBalance(chainIds: number[], account: string): Promise<string> {
    let userBalance = new BN(0);
    (await Promise.all(chainIds.map((chainId) => this._getUserBalance(chainId, account)))).forEach(
      (chainBalance) => (userBalance = userBalance.add(chainBalance))
    );
    return this.web3.utils.fromWei(userBalance);
  }
  // Finish Token Contract Methods

  // Start Stake Contract Methods

  async getAllLockingPeriodsDetails(chainId: number): Promise<any> {
    const stakeContracts = this.getStakingContractForChainId(chainId);
    const { readOnlyContract: stakeContract } = stakeContracts;
    if (!stakeContract) return [];
    return await stakeContract.methods.getAllLockingPeriodsDetails().call();
  }

  async calculateRewards(chainId: number, amount: number = 10, periodId: number = 0): Promise<any> {
    const stakeContracts = this.getStakingContractForChainId(chainId);
    const { readOnlyContract: stakeContract } = stakeContracts;
    return this.web3.utils.fromWei(
      await stakeContract.methods
        .calculateRewards(await this.web3.utils.toBN(await this.web3.utils.toWei(amount.toString(), "ether")), periodId)
        .call()
    );
  }

  async getTotalBalance(chainIds: number[], address: string): Promise<string> {
    let balance = new BN(0);
    (await Promise.all(chainIds.map((chainId) => this._getBalance(chainId, address)))).forEach((chainBalance) => {
      balance = balance.add(chainBalance);
    });
    return this.web3.utils.fromWei(balance);
  }

  async getBalance(chainId: number, address: string): Promise<string> {
    const balance = await this._getBalance(chainId, address);
    return this.web3.utils.fromWei(balance);
  }

  async _getMixedStakeBalance(chainId: number, address: string): Promise<BN> {
    const stakeContracts = this.getStakingContractForChainId(chainId);
    const { readOnlyContract: stakeContract, contractEntity } = stakeContracts;
    if (contractEntity?.contractType === ContractType.STAKING) {
      const balance = await stakeContract.methods.balanceOf(address).call();
      return this.web3.utils.toBN(`${balance}`);
    } else {
      const tokenCount = this.web3.utils.toBN(await stakeContract.methods.balanceOf(address).call()).toNumber();
      const getTokenIdPromises: Promise<number>[] = [];
      for (let index = 0; index < tokenCount; index++) {
        getTokenIdPromises.push(stakeContract.methods.tokenOfOwnerByIndex(address, index).call());
      }
      const tokenIds = await Promise.all(getTokenIdPromises);

      const getTokenIdBalancePromises: Promise<number>[] = [];
      tokenIds.forEach((tokenId) => getTokenIdBalancePromises.push(stakeContract.methods.shiBalanceOf(tokenId).call()));
      const tokenIdsBalance = await Promise.all(getTokenIdBalancePromises);
      let balance = new BN("0");

      tokenIdsBalance.forEach((tokenBalance) => {
        const tokenBalanceBN = this.web3.utils.toBN(`${tokenBalance}`);
        balance = balance.add(tokenBalanceBN);
      });
      return balance;
    }
  }

  async _getBalance(chainId: number, address: string): Promise<BN> {
    const rewardsContracts = this.getRewardsContractForChainId(chainId);
    const { readOnlyContract: rewardContract } = rewardsContracts;
    try {
      let originalStakeBalance = await this._getMixedStakeBalance(chainId, address);
      let rewardStakeBalance = new BN("0");
      if (rewardContract) {
        const rwBalance = await rewardContract.methods.balanceOf(address).call();
        rewardStakeBalance = await this.web3.utils.toBN(`${rwBalance}`);
      }
      return originalStakeBalance.add(rewardStakeBalance);
    } catch (e: any) {
      console.log(e.message);
    }
    return new BN("0");
  }

  async mintRequired(chainId: number, address: string): Promise<any> {
    const stakeContracts = this.getStakingContractForChainId(chainId);
    const { readOnlyContract: stakeContract, contractEntity } = stakeContracts;
    if (contractEntity?.contractType === ContractType.NFTSTAKING) {
      let balance = this.web3.utils.toBN(await stakeContract.methods.balanceOf(address).call());
      return balance.eq(new BN("0"));
    } else {
      return false;
    }
  }

  async mint(chainId: number, address: string) {
    const stakeContracts = this.getStakingContractForChainId(chainId);
    const { readOnlyContract, contract, contractEntity } = stakeContracts;
    if (contractEntity?.contractType === ContractType.NFTSTAKING) {
      const mintPrice = await readOnlyContract.methods.getMintingPrice().call();
      const response = await contract.methods.mint().send({ from: address, value: mintPrice });
    }
  }

  async staking(chainId: number, amount: number = 10, period: number = 0, address: string): Promise<any> {
    const stakeContracts = this.getStakingContractForChainId(chainId);
    const { contract, contractEntity } = stakeContracts;
    if (contractEntity?.contractType === ContractType.STAKING) {
      return await contract.methods
        .stake(this.web3.utils.toWei(amount.toString()), period)
        .send({ from: address }, (error: any, transactionHash: any) => {
          this.transactionHash.next(transactionHash);
        });
    } else {
      const tokenId = await contract.methods.tokenOfOwnerByIndex(address, 0).call();
      /*NOTA: Al period le quitamos 1 xq le asignamos ids de 1 a N y el contrato necesita el index */
      return await contract.methods
        .stake(tokenId, this.web3.utils.toWei(amount.toString()), period - 1, false)
        .send({ from: address }, (error: any, transactionHash: any) => {
          this.transactionHash.next(transactionHash);
        });
    }
  }

  async withdraw(chainId: number, id: any): Promise<void> {
    const stakeContracts = this.getStakingContractForChainId(chainId);
    const { contract: stakeContract } = stakeContracts;
    return await stakeContract.methods.withdraw(id).send({ from: this.account }, (error: any, transactionHash: any) => {
      this.transactionHash.next(transactionHash);
    });
  }

  async withdrawRewards(chainId: number): Promise<void> {
    const rewardsContracts = this.getRewardsContractForChainId(chainId);
    const { contract: rewardContract } = rewardsContracts;
    return await rewardContract.methods.withdraw().send({ from: this.account }, (error: any, transactionHash: any) => {
      this.transactionHash.next(transactionHash);
    });
  }

  // Finish Stake Contract Methods

  //Start Collectible NFT  Methods

  async getNftCollectedBalance(account: string, nftCollectibleContract: ContractChainAbi) {
    const { readOnlyContract } = nftCollectibleContract;
    return Number(await readOnlyContract.methods.balanceOf(account).call());
  }

  async getNftCollectedLevel(account: string, nftCollectibleContract: ContractChainAbi) {
    const { readOnlyContract } = nftCollectibleContract;
    const tokenIds = await this._api.getTokenIdsByContractAndOwner(nftCollectibleContract.contractEntity, account);
    const getLevelForTokenIdPromises: Promise<{ tokenId: string; level: number }>[] = tokenIds.map(async (tokenId) => {
      const level = await this.getNftLevel(tokenId, nftCollectibleContract);

      return { tokenId, level };
    });

    const tokensWithLevel = await Promise.all(getLevelForTokenIdPromises);

    let tokenMaxLevel = { tokenId: "", level: -1 };
    tokensWithLevel.forEach((item) => {
      if (item.level > tokenMaxLevel.level) {
        tokenMaxLevel = item;
      }
    });

    let metadata: CollectibleNftMetadata | undefined = undefined;
    if (tokenMaxLevel.level > 0) {
      const metadataUri = await readOnlyContract.methods.tokenURI(tokenMaxLevel.tokenId).call();
      metadata = await fetch(metadataUri).then((res) => res.json());
      return { ...tokenMaxLevel, metadata };
    }
    return undefined;
  }

  async getNftLevel(tokenId: string, nftCollectibleContract: ContractChainAbi) {
    const { readOnlyContract } = nftCollectibleContract;
    return Number(await readOnlyContract.methods.getNftLevel(tokenId).call());
  }

  async getLevels(nftCollectibleContract: ContractChainAbi) {
    const { readOnlyContract } = nftCollectibleContract;
    const result = await readOnlyContract.methods.getLevels().call();
    return { min: Number(result[0]), max: Number(result[1]) };
  }

  async upgrade(tokenId: string, targetLevel: string, nftCollectibleContract: ContractChainAbi) {
    const { contract } = nftCollectibleContract;
    return await contract.methods.upgradeNFT(tokenId, targetLevel).send({ from: this.account });
  }

  async downgrade(tokenId: string, targetLevel: string, nftCollectibleContract: ContractChainAbi) {
    const { contract } = nftCollectibleContract;
    return await contract.methods.downgradeNFT(tokenId, targetLevel).send({ from: this.account });
  }

  async getUpgradePrice(currentLevel: string, targetLevel: string, nftCollectibleContract: ContractChainAbi) {
    const { readOnlyContract } = nftCollectibleContract;
    const upgradePrice = Number(
      this.web3.utils.fromWei(await readOnlyContract.methods.getUpgradePrice(currentLevel, targetLevel).call())
    );
    return upgradePrice;
  }

  fromWei(amount: string) {
    return this.web3.utils.fromWei(amount);
  }

  toWei(amount: string) {
    return this.web3.utils.toWei(amount);
  }

  async getDowngradeCost(tokenId: string, targetLevel: string, nftCollectibleContract: ContractChainAbi) {
    const { readOnlyContract } = nftCollectibleContract;
    const downgradeCost = await readOnlyContract.methods.getDowngradeCost(tokenId, targetLevel).call();

    return { price: this.web3.utils.fromWei(downgradeCost[0]), burn: this.web3.utils.fromWei(downgradeCost[1]) };
  }
  //Finish Collectible NFT  Methods
}
