<marquee scrollamount="10" loop="infinite" sandbox="allow-scripts" class="neon">
  <span> {{ "app.current" | translate }} {{ coingecko | number: "1.0-3" }} usd </span>
  <span>&nbsp;&nbsp;&nbsp;</span>
  <span> {{ "app.current" | translate }} {{ coingecko | number: "1.0-3" }} usd </span>
  <span>&nbsp;&nbsp;&nbsp;</span>
  <span> {{ "app.current" | translate }} {{ coingecko | number: "1.0-3" }} usd </span>
  <span>&nbsp;&nbsp;&nbsp;</span>
  <span> {{ "app.current" | translate }} {{ coingecko | number: "1.0-3" }} usd </span>
  <span>&nbsp;&nbsp;&nbsp;</span>
  <span> {{ "app.current" | translate }} {{ coingecko | number: "1.0-3" }} usd </span>
  <span>&nbsp;&nbsp;&nbsp;</span>
  <span> {{ "app.current" | translate }} {{ coingecko | number: "1.0-3" }} usd </span>
  <span>&nbsp;&nbsp;&nbsp;</span>
  <span> {{ "app.current" | translate }} {{ coingecko | number: "1.0-3" }} usd </span>
</marquee>
