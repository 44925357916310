import { Component, OnInit } from "@angular/core";

@Component({
  selector: "loader-dialog",
  templateUrl: "loader-dialog.component.html",
  styleUrls: ["loader-dialog.component.scss"],
})
export class LoaderDialogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
